/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "../assets/ConnectedLogo.png";
import notification from "../assets/notification-bing.svg";
import ladyperson from "../assets/saveavatar.jpg";
import profileperson from "../assets/profile.svg";
import profilenotification from "../assets/notification-bing1.svg";
import bookmark1 from "../assets/bookmark1.svg";
import settings from "../assets/setting-2.svg";
import logout from "../assets/logout.svg";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useQuery } from "@tanstack/react-query";
import { getNotification } from "../api";
import { useState } from "react";


const Navbar = () => {
    const { setUser, setToken } = useProtectedRoutesContext();
    const navigate = useNavigate();

    let storedObject = localStorage.getItem('user');



    let firstname = '';
    let lastname = '';
    let jobtitle = '';
    let avatar = '';

    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
        lastname = parsedObject?.last_name;
        jobtitle = parsedObject?.job_title;
        avatar = parsedObject?.avatar;
    }

    const { data } = useQuery({
        queryKey: ["getNotification"],
        queryFn: () => getNotification(),
    });

    // State for pagination
    const [pageNumber] = useState(0);
    const resultsPerPage = 5;
    const pagesVisited = pageNumber * resultsPerPage;


    // console.log("Notify", data?.data?.data)

    return (
        <div className="">
            <nav className="navbar navbar-bcg navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" to="https://yourconnectednewsroom.com"><img src={logo} alt="" className="profile-logo" /></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/queries">Queries</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/myleaks">Leaks</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/profilesearch">Profile Search</NavLink>
                            </li>
                        </ul>
                        <form className="form-inline my-2 my-lg-0">
                            <div className="nav-item dropdown">
                                <Link className="" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={notification} alt="" />
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {data?.data?.data && data.data.data.length > 0 ? (
                                        <div>
                                            <p className="section-title">Latest notifications</p>
                                            {data?.data?.data?.slice(pagesVisited, pagesVisited + resultsPerPage).map((notification, index) => (
                                                <div key={index} className="profile-person-flex-bg">
                                                    <div className="profile-person-flex">
                                                        <Link className="dropdown-item notice" to={`/queries/${notification?.query_uuid}`}>
                                                            {notification.message}
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>You don't have any notifications</p>
                                    )}
                                </div>
                            </div>

                            <div className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {avatar ? (<img src={avatar} alt="" className="profile-person-img ml-4" />) : (<img className="koconnected-img" src={ladyperson} alt="" />)}
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-container-profile">
                                        <div className="person-container">
                                            {avatar ? (<img src={avatar} alt="" className="person-profile-display" />) : (<img src={ladyperson} alt="" />)}
                                        </div>
                                        <h6 className="dropdown-container-profile-name">{firstname} {lastname}</h6>
                                        <p className="dropdown-container-profile-role">{jobtitle}</p>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={profileperson} alt="" />
                                        <NavLink to="/profileinfo" className="dropdown-item">My Profile</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={profilenotification} alt="" />
                                        <NavLink to="/notification" className="dropdown-item">Notifications</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={bookmark1} alt="" />
                                        <Link className="dropdown-item" to="/queries">Archived Queries</Link>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div className="profile-person-flex">
                                        <img src={settings} alt="" />
                                        <NavLink to="/settings" className="dropdown-item">Settings</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <img src={logout} alt="" />
                                        <Link className="dropdown-item" to="#"
                                            onClick={() => {
                                                setToken(null);
                                                setUser(null);
                                                localStorage.clear();
                                                window.location.reload();
                                            }}
                                        >Logout</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
